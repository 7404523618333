@import '~antd/dist/antd.less';
@import './variables';

@primary-color: #643ced;
@link-color: #38a9ff;
@success-color: #5cb85c;
@warning-color: #faad14;
@error-color: #f5222d;
@font-size-base: 14px;
@heading-color: rgb(38, 38, 39);
@text-color: rgb(38, 38, 39);
@text-color-secondary: rgba(0, 0, 0, 0.45);
@disabled-color: rgba(0, 0, 0, 0.25);
@border-radius-base: 3px;
@border-color-base: #eee;
@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15);

@btn-font-weight: 400;
@btn-height-base: 44px;
@alert-success-border-color: @success-color;
@alert-error-border-color: @primary-color;

// Input
@input-height-base: 44px;
@input-height-lg: 44px;
@form-item-margin-bottom: 15px;
@input-padding-horizontal: 10px;

// Font
//@font-family-no-number: @font-family-base;
//@font-family: "Helvetica Neue For Number", @font-family-no-number;

.ant-form-explain {
  padding-bottom: 15px;
}

.ant-btn {
  padding-left: 40px;
  padding-right: 40px;
}

.ant-card-head-title {
  font-size: @font-size-sm-extra;
}

.btn--block {
  width: 100%;
}

.ant-form-item-label {
  line-height: 1.3;
}

.ant-select-dropdown-menu-item {
  padding: 12px 12px;
}

.ant-card--shadow {
  -webkit-box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08);
  -moz-box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08);
  box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08);
  .ant-card-head-title {
    padding: 24px 0;
    font-size: 15px;
    color: #666;
    .ant-card-head {
      border-color: @border-color;
    }
  }
}

.ant-modal-title {
  font-size: 16px;
}

.ant-button {
  text-shadow: none;
}

.btn--facebook-bg {
  background-color: #3b5998 !important;
  border-color: #3b5998 !important;
  &:hover,
  &:focus {
    background-color: #3b5998 !important;
    border-color: #3b5998 !important;
    opacity: 0.8;
  }
}

.btn--facebook-bg[disabled] {
  color: #fff;
  &:hover,
  &:focus {
    color: #fff !important;
  }
}

.ant-alert-with-description .ant-alert-message {
  font-size: @font-size-md;
}

.ant-alert-description {
  color: #777;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #fafafa;
  &:after {
    display: none;
  }
}

.ant-menu.ant-menu-light.ant-menu-root.ant-menu-vertical.menu--shadow {
  box-shadow: 1px 1px 10px 1px #eee;
  border-radius: 3px;
  border-right: none;
}

.ant-popover-title {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  border-bottom: 1px solid #eee;
}

.ant-divider-inner-text {
  font-size: 15px;
}

.ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-bottom: 0;
  margin-left: 48px;
  font-size: 16px;
  line-height: 1.1;
}

.TeamSwitcherDropdownMenu {
  .ant-select-dropdown-menu {
    max-height: unset !important;
  }
}
.ant-message .anticon {
  font-size: 16px;
}

.FormItemComments {
  .ant-modal-body {
    padding: 0;
  }
}

.UnsplashPhotoSearcherModal {
  .ant-modal-body {
    padding: 0;
  }
}
